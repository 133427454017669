import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import JoditEditor from "jodit-react";
import { Orders_edit } from '../../../../store/storeReducers/OrdersReducer';
import { status } from '../../../../constants/status';
import { get_order } from '../../../../functions/Ordersfunction';
import { update_Line } from '../../../../store/storeReducers/LoadingReducer';

const UpdateOrderView = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const navigate = useNavigate();
    const [order, setOrder] = useState({
        id: '',
        user_id: '',
        service_id: '',
        status: '',
        imei: '',
        response: '',
        service: {
            name: ''
        },
        user: {
            email: ''
        },
    });

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getOrder(param.id);
        }
    }, [param.count]);

    const handleChange = (e) => {
        setOrder({
            ...order,
            [e.target.name]: e.target.value
        });
    };

    const getOrder = async (id) => {
        try {
            const res = await get_order({ id, road: 'order' });
            setOrder(res.data.order);
            dispatch(Orders_edit(res.data.order));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }


    return (
        <div>
            {
                !line &&
                <div>
                    <table className='table-add'>
                        <tbody>
                            <tr>
                                <td className="fieldlabel">Num order:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none'>
                                        {order.id}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="fieldlabel">Imei:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none'>
                                        {order.imei}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="fieldlabel">Service:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none'>
                                        {order.service.name}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="fieldlabel">Service price:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none '>
                                        {parseFloat(order.price).toFixed(2)}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="fieldlabel">Status:</td>
                                <td className='fieldlabel'>
                                    <select
                                        disabled="disabled"
                                        className='outline-none pd-0-10p'
                                        value={order.status}
                                        name="status"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {
                                            [{ "name": "--Select Status--", status: "" }, ...status].map((s, k) => {
                                                return (
                                                    <option key={k} value={s.status}>
                                                        {s.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="fieldlabel">User:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none '>
                                        {order.user.email}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="fieldlabel" style={{ width: '60%' }}>
                        <JoditEditor
                            value={order.response}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setOrder((state) => ({ ...state, response: newContent }))}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdateOrderView