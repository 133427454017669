import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_subs= (u) => {
    var url = u.page ? u.page : `${api}/subscriptions`;
    return axiosConfig.get(url, {
        params: {
            road: u.road,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}