import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subs: {
        subs: { data: [] }
    }
}

export const subsSlice = createSlice({
    name: 'subs',
    initialState,
    reducers: {
        SetSubs: (state, action) => {
            state.subs.subs = action.payload
        },
    }
})

export const { SetSubs } = subsSlice.actions

export default subsSlice.reducer