import React, { useState, useEffect } from 'react';
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { api } from '../../../../api/ConnectApi';
import axios from "axios";
import moment from 'moment'
import { OrderStatus } from '../../../../components/ImagenStatus';
import Paginator from '../../../../components/Paginator';
import Search from '../../../../components/Search';
import { get_user } from '../../../../functions/Usersfunction';
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const OrdersUserView = () => {
    const param = useOutletContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [orders, setOrders] = useState({ data: [] });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getOrders();
        }
    }, [param.count]);

    const getOrders = async (value) => {
        try {
            const order = {
                id: param.id,
                road: 'orders',
                search: value && value.search ? value.search : '',
                per_page: value && value.per_page ? value.per_page : 50,
                page: value && value.page ? value.page : ''
            }
            const res = await get_user(order);
            setOrders(res.data.orders);
        } catch (error) {
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getOrders(p)}
                        value={"Search by imei..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Imei</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Service</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Status</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Price</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Day</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders.data.map((ord, index) => {
                                        const status = OrderStatus(parseFloat(ord.status));
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{ord.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{ord.imei}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 white_space">{ord.service.name}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {status}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{parseFloat(ord.price).toFixed(2)}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {moment(ord.created_at).format('Y-MM-DD')}</td>
                                                <td onClick={() => navigate(`/get-orders/order-info?reference_id=${ord.id}`)} className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer">< AiOutlineEdit /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={orders}
                action={(p) => getOrders(p)}
            />
        </div>
    );
}

export default OrdersUserView