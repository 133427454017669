import React, { useEffect, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Shortcuts } from '../Shortcuts';
import { useNavigate } from 'react-router-dom';
import { SetOut } from '../store/storeReducers/AuthReducer';
import { sign_out } from '../functions/Authfunction';
import LogoSvg from '../assets/svg/Logo_fi.svg';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from "react-redux";

const Navbar = () => {
    const user = useSelector((state) => state.auth.auth.user);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const style = document.documentElement.style;
    const [d, setD] = useState({
        index: '',
        value: false
    });
   
    useEffect(() => {
        handleMobile();
    }, [])

    const handleStyle = (e) => {
        if (style.getPropertyValue('--Hg-Navbar') !== '40px') {
            if (e && e.path) {
                style.setProperty('--Hg-Navbar', '40px');
                style.setProperty('--Subs-Navbar', 'none');
                navigate(e.path);
                setD({
                    ...d,
                    value: false
                });
            }
        }
    }

    const handleMobile = () => {
        if (style.getPropertyValue('--Hg-Navbar') === '40px') {
            style.setProperty('--Hg-Navbar', 'auto');
            style.setProperty('--Subs-Navbar', 'inline-block');
        } else {
            style.setProperty('--Hg-Navbar', '40px');
            style.setProperty('--Subs-Navbar', 'none');
        }
    }

    const submitLogout = async () => {
        try {
            const res = await sign_out();
            cookies.remove('auth_token');
            dispatch(SetOut(res.data))
            navigate('/')
        } catch (error) {
        }
    }

    const test = (e) => {
        setD({
            ...d,
            index: e,
            value: true
        });
    }

    return (
        <div className='nav-bar'>
            <div className='center-objects'>
                <img className='mg-left_16 max-wd_40 max-hd_40' src={LogoSvg} />
                <span className='pn ft-size_11'>Panel 1.0.1v</span>
            </div>
            <div className='wd-100p pst-absolute cont-center'>
                <span className='pn ft-size_15'>
                    Balance {(parseFloat(user.credits).toFixed(2) || (0).toFixed(2))}
                </span>
            </div>
            <div className='nav-sideopen'>
                <a onClick={() => handleMobile()}>
                    <i className='select-none'><GiHamburgerMenu /> </i>
                </a>
                <ul className='pd-10-0 nav-sidebar'>
                    {
                        Shortcuts.map((s, index) => {
                            return (
                                <li
                                    className={`sub-show select-none pd-10 dp-flex itm-center short-one `}
                                    key={index}
                                >
                                    <a onClick={() => test(index)} className='ddd dp-flex nav-link'>
                                        <i className='pd-right_10 ft-size_20 dp-flex itm-center'>{s.icon}</i>
                                        <span className='' >
                                            {s.name}
                                        </span>
                                    </a>
                                    {
                                        d.value ?
                                            <div className={d.index == index ? 'subs-nav' : 'dp-none'}>
                                                {
                                                    s.sub.map((sub, key) => {
                                                        return (
                                                            <a onClick={() => { sub.subname === 'Sign out' ? submitLogout() : handleStyle(sub) }} key={key} className='sub-nav'>
                                                                {sub.subname}
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            ''
                                    }

                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default Navbar