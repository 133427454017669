import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    services: {
        services: {
            data: []
        },
        servers: {
            data: []
        },
    }
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        SetServices: (state, action) => {
            state.services.services = action.payload
        },
        Update_services: (state, action) => {
            let b = state.services.services.data.map(service => {
                if (service.id !== action.payload.id) {
                    return service;
                } else {
                    return action.payload;
                }
            });
            state.services.services = {
                ...state.services.services,
                data: b
            }
        },
        Delete_services: (state, action) => {
            let a = state.services.services.data.filter(service => {
                return service.id !== action.payload.id;
            })
            state.services.services = {
                ...state.services.services,
                data: a
            }
        },
    }
})

export const { SetServices, Update_services, Delete_services, SetServers, Update_servers, Delete_server } = servicesSlice.actions

export default servicesSlice.reducer