import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_orders = (u) => {
    var url = u.page ? u.page : `${api}/orders`;
    return axiosConfig.get(url, {
        params: {
            road: u.road,
            search: u.search,
            per_page: u.per_page,
            type: u.type
        },
    }).then(response => response);
}

export const get_order = (order) => {
    var url = `${api}/orders`;
    return axiosConfig.get(`${url}/${order.id}`, {
        params: {
            road: order.road
        },
    }).then(response => response);
}

export const update_order = (order) => {
    var url = `${api}/orders`;
    return axiosConfig.put(`${url}/${order.id}`, order, {
    }).then(response => response);
}

export const delete_order = (id) => {
    var url = `${api}/orders`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}

export const await_order = (order) => {
    var url = `${api}/status`;
    return axiosConfig.put(`${url}`, order, {
    }).then(response => response);
}