import React, { useEffect, useState } from 'react';
import { create_user } from "../../../functions/Usersfunction";
import { useSelector } from "react-redux";


export const AddUserView = () => {
    const [loading, setLoad] = useState(false);
    const [error, setErr] = useState({ err: [] });
    const line = useSelector((state) => state.loading.loading.line);
    const [user, setUser] = useState({
        id: '',
        fullname: '',
        email: '',
        password: ''
    });

    useEffect(() => {
       
    }, []);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const createUser = async () => {
        setLoad(true);
        try {
            const res = await create_user(user);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                } else if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <table className='table-add' >
                        <tbody>
                            <tr>
                                <td className='fieldlabel'>Full name:</td>
                                <td className='fieldlabel'>
                                <form autoComplete="off">     
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        name='fullname'
                                        type="email"
                                        placeholder="Full name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </form>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.fullname}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Email:</td>
                                <td className='fieldlabel'>
                                <form autoComplete="off">   
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        name="email"
                                        type="email"
                                        placeholder="Email:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </form>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.email}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Password:</td>
                                <td className='fieldlabel'>
                                <form autoComplete="off">
                                    <input
                                        className='outline-none pd-0-10p'
                                        name="password"
                                        type="password"
                                        placeholder="New password:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </form>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.password}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <button
                                        disabled={loading}
                                        className='select-none'
                                        onClick={() => createUser()}
                                    >
                                        {
                                            loading ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );

}

export default AddUserView