import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_banks = (u) => {
    var url = u.page ? u.page : `${api}/banks`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const get_bank = (u) => {
    var url = u.page ? u.page : `${api}/banks/${u.id}`;
    return axiosConfig.get(`${url}`, {
        params: {
            action: u.action,
            search: u.search
        },
    }).then(response => response);
}

export const create_bank = (bank) => {
    var url = `${api}/banks`;
    return axiosConfig.post(`${url}`, bank, {
    }).then(response => response);
}

export const update_bank = (bank) => {
    var url = `${api}/banks`;
    return axiosConfig.put(`${url}/${bank.id}`, bank, {
    }).then(response => response);
}

export const delete_bank = (id) => {
    var url = `${api}/banks`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}