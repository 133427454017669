import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { OrderStatus } from "../../../components/ImagenStatus";
import Paginator from '../../../components/Paginator';
import { SetSubs } from '../../../store/storeReducers/SubsReducer';
import Search from '../../../components/Search';
import { update_Line } from '../../../store/storeReducers/LoadingReducer';
import { get_subs } from '../../../functions/Subsfunction';

const GetSubsView = () => {
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const getSubs = useSelector((state) => state.subs.subs.subs);

    useEffect(() => {
        if (getSubs && !getSubs.data[0]) {
            dispatch(update_Line(true));
            getSubscription();
        }
    }, []);

    const getSubscription = async (value) => {
        try {
            const subscription = {
                road: 'subscriptions',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_subs(subscription);
            dispatch(SetSubs(res.data.subs));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getSubscription(p)}
                        value={"Search by reference..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">User</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Price</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Start</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">End</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Register Day</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getSubs.data.map((sub, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{sub.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{sub.user.email}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{(parseFloat(sub.price).toFixed(2) || (0).toFixed(2))}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{sub.start}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{sub.end}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{moment(sub.created_at).format('Y-MM-DD')}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={getSubs}
                action={(p) => getSubscription(p)}
            />
        </div>
    );

}

export default GetSubsView;