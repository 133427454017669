import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './storeReducers/AuthReducer';
import UsersReducer from './storeReducers/UsersReducer';
import BanksReducer from './storeReducers/BanksReducer';
import ServicesReducer from './storeReducers/ServicesReducer';
import TicketsReducer from './storeReducers/TicketsReducer';
import OrdersReducer from './storeReducers/OrdersReducer';
import LoadingReducer from './storeReducers/LoadingReducer';
import SubsReducer from './storeReducers/SubsReducer';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        users: UsersReducer,
        banks: BanksReducer,
        services: ServicesReducer,
        tickets: TicketsReducer,
        orders: OrdersReducer,
        loading: LoadingReducer,
        subs: SubsReducer
    },
})