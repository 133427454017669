import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const instance = axios.create({
    // baseURL: 
});
instance.interceptors.request.use(function (config) {
    config.headers["Authorization"] = cookies && cookies.get('auth_token') && `Bearer ${cookies.get('auth_token')}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
});

export default instance;
