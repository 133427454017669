import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    users: {
        users: { data: [] }
    },
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        SetUsers: (state, action) => {
            state.users.users = action.payload
        },
        Update_users: (state, action) => {
            let b = state.users.users.data.map(user => {
                if (user.id !== action.payload.id) {
                    return user;
                } else {
                    return action.payload;
                }
            });
            state.users.users = {
                ...state.users.users,
                data: b
            }
        },
        Balance_users: (state, action) => {
            let b = state.users.users.data.map(user => {
                if (user.id !== action.payload.id) {
                    return user;
                } else {
                    return { ...user, balance: action.payload.balance };
                }
            });
            state.users.users = {
                ...state.users.users,
                data: b
            }
        },
        Delete_users: (state, action) => {
            let a = state.users.users.data.filter(user => {
                return user.id !== action.payload.id;
            })
            state.users.users = {
                ...state.users.users,
                data: a
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { SetUsers, Update_users, Delete_users, Balance_users } = usersSlice.actions

export default usersSlice.reducer