import React, { useState } from 'react';
import axios from 'axios';
import { api } from '../../api/ConnectApi';
import { useDispatch } from "react-redux";
import { SetLogin } from '../../store/storeReducers/AuthReducer';
import LogoSvg from '../../assets/svg/Logo_fi.svg';
import { useCookies } from 'react-cookie';

const LoginView = () => {
    const [cookies, setCookie] = useCookies();
    const dispatch = useDispatch();
    const [admin, setAdmin] = useState({
        email: '',
        password: ''
    });
    const url = document.domain;
    const [error, setErr] = useState({
        err: []
    });
    const [loading, setLoad] = useState(false);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value
        })
    }

    const signIn = async () => {
        setLoad(true);
        axios.post(`${api}/login`, admin, {
        }).then(res => {
            setCookie('auth_token', JSON.stringify(res.data.access_token), { path: '/' });
            dispatch(SetLogin(res.data));
            setLoad(false);
        }).catch(err => {
            setLoad(false);
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
                if (err.response.data.credentials) {
                    alert(err.response.data.credentials);
                }
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
            }
        })
    }

    return (
        <div className='container'>
            <div className="provider bg-232f3e ">
                <div className="dp-flex">
                    <div className="colll ft-size_13 ft-weight_bold ft-family-monospace">
                        <span className="color-C792EA  ">{`Panel->`}</span>
                        <span className="color-C3E88D ">{`provided->`}</span>
                        <span className="color-FF5572  ">by(</span>
                        <span className="color-3dd0f8  ">[family</span>
                        <span className="color-BEC5D4  ">,</span>
                        <span className="color-FFCB8B ">eneyvol]</span>
                        <span className="color-FF5572  ">)</span>
                        <span className="color-BEC5D4">;</span>
                    </div>
                </div>
            </div>
            <div className='order-form cont-center'>
                <div className='form dp-flex'>
                    <div className='tx-center'>
                        <img className="img-ef" src={LogoSvg} />
                        <span className="color-black">@{url}</span>
                    </div>
                    <div >
                        <form className='form-data dp-flex' autoComplete="off">     
                        <input 
                            className='inp-data outline-none bd-none' 
                            onChange={(e) => handleChange(e)} 
                            name='email' 
                            placeholder='Email:' />
                        </form>
                        <label className='color-err ft-size_10 pd-5'>{error.email}</label>
                    </div>
                    <div >
                        <form className='form-data dp-flex' autoComplete="off">     
                        <input 
                            className='inp-data outline-none bd-none' 
                            onChange={(e) => handleChange(e)} 
                            name='password' 
                            placeholder='*****' 
                            type={'password'} />
                        </form>
                        <label className='color-err ft-size_10 pd-5'>{error.password}</label>
                    </div>
                    <button className='btt-submit cursor-pointer' onClick={() => signIn()}>
                        {
                            loading ?
                                <div className='spin'></div>
                                :
                                <span className='color-white'>Sign In</span>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LoginView