import React, { useState, useEffect } from 'react';
import { Outlet, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { NavIndex } from '../../../components/NavigateIndex';

const IndexBank = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('reference_id')
    const [count, setCount] = useState(0);
    const [className, setClass] = useState(location && location.pathname);
    const belong = 'bank'

    useEffect(() => {
        if (location) {
            setClass(location.pathname)
        }
    }, [location])

    const handleClass = (c) => {
        setCount(count + 1);
        setClass(c.className);
        navigate(`${c.path}?reference_id=${id}`);
    }

    return (
        <div>
            <NavIndex
                path={'/get-banks'}
                handleClass={(c) => handleClass(c)}
                className={className}
                belong={belong}
            />
            <Outlet context={{ count: count, id: id }} />
        </div>
    )
}

export default IndexBank