import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from 'moment'
import { AiOutlineEdit } from "react-icons/ai";
import { OrderStatus } from '../../../../components/ImagenStatus';
import Paginator from '../../../../components/Paginator';
import Search from '../../../../components/Search';
import { get_user } from '../../../../functions/Usersfunction';
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const SubsUserView = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const [subs, setSubscription] = useState({ data: [] });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getSubscription();
        }
    }, [param.count]);


    const getSubscription = async (value) => {
        try {
            const subscription = {
                id: param.id,
                road: 'subscription',
                search: value && value.search ? value.search : '',
                per_page: value && value.per_page ? value.per_page : 50,
                page: value && value.page ? value.page : '',
            };
            const res = await get_user(subscription);
            setSubscription(res.data.subs);
        } catch (error) {
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getSubscription(p)}
                        value={"Search by reference..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Time</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Price</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Start</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">End</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    subs.data.map((sub, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{sub.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{sub.time}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{(parseFloat(sub.price).toFixed(2) || (0).toFixed(2))}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{sub.start}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{sub.end}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={subs}
                action={(p) => getSubscription(p)}
            />
        </div>
    );

}

export default SubsUserView;