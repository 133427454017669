import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_users = (u) => {
    var url = u.page ? u.page : `${api}/users`;
    return axiosConfig.get(url, {
        params: {
            road: u.road,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const create_user = (user) => {
    var url = `${api}/users`;
    return axiosConfig.post(url, user, {
    }).then(response => response);
}


export const get_user = (u) => {
    var url = u.page ? u.page : `${api}/users/${u.id}`;
    return axiosConfig.get(`${url}`, {
        params: {
            road: u.road,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}


export const update_user = (user) => {
    var url = `${api}/users`;
    return axiosConfig.put(`${url}/${user.id}`, user, {
    }).then(response => response);
}

export const delete_user = (id) => {
    var url = `${api}/users`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}
