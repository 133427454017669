import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_tickets = (u) => {
    var url = u.page ? u.page : `${api}/tickets`;
    return axiosConfig.get(url, {
        params: {
            road: u.road,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const get_ticket = (u) => {
    var url = u.page ? u.page : `${api}/tickets/${u.id}`;
    return axiosConfig.get(`${url}`, {
        params: {
            road: u.road,
            search: u.search
        },
    }).then(response => response);
}


export const update_ticket = (ticket) => {
    var url = `${api}/tickets`;
    return axiosConfig.put(`${url}/${ticket.id}`, ticket, {
    }).then(response => response);
}

export const delete_ticket = (id) => {
    var url = `${api}/tickets`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}